import classnames from 'classnames'
import { type FunctionComponent, useEffect, useState } from 'react'
import { CuiFlexGroup, CuiPad } from 'src/cui/components'
import { palette } from 'src/cui/themes/palette'
import { ReactComponent as CartaLogo } from 'src/images/logos/logo.svg'
import { useIsMobile } from 'src/utils/mediaQuery'
import styled from 'styled-components'

type CuiStaticNavbarStyle = 'light' | 'accent'

type Props = {
  style: CuiStaticNavbarStyle
}

type StaticNavbarRootProps = { $style: CuiStaticNavbarStyle }

export const STATIC_NAVBAR_HEIGHT = '72px'

const StaticNavbarRoot = styled.div<StaticNavbarRootProps>`
  position: sticky;
  top: 0;
  height: ${STATIC_NAVBAR_HEIGHT};
  width: 100%;
  display: flex;
  align-items: center;
  color: ${({ $style }) => ($style === 'light' ? palette.neutral90 : palette.white)};

  &.StaticNavbarRoot--isScrolled {
    background-color: ${({ $style }) => ($style === 'light' ? palette.white : palette.brandMain80)};
    box-shadow: 0px 1.5px 12px rgba(0, 0, 0, 0.2);
  }
`

export const StaticNavbar: FunctionComponent<Props> = ({ style }) => {
  const isMobile = useIsMobile()

  const isAtTopOfWindow = useIsAtTopOfWindow()

  return (
    <StaticNavbarRoot
      $style={style}
      className={classnames({
        'StaticNavbarRoot--isScrolled': isAtTopOfWindow === false,
      })}
    >
      <CuiPad horizontalSize={isMobile ? 'xs' : 'm'}>
        <CuiFlexGroup>
          <CartaLogo />
        </CuiFlexGroup>
      </CuiPad>
    </StaticNavbarRoot>
  )
}

const SCROLL_HEIGHT = 20

function useIsAtTopOfWindow() {
  const [isAtTop, setIsAtTop] = useState(window.scrollY < SCROLL_HEIGHT)

  useEffect(() => {
    const onScroll = () => {
      setIsAtTop(window.scrollY < SCROLL_HEIGHT)
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return isAtTop
}
