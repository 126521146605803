import { type FunctionComponent, useState } from 'react'
import useKonami from 'react-use-konami'

import { CuiThemeProvider } from '../cui/CuiThemeProvider'

const IS_DARK_THEME_ON_LOCAL_STORAGE_KEY = 'IS_DARK_THEME_ON_LOCAL_STORAGE_KEY'

export const ThemeProvider: FunctionComponent = ({ children }) => {
  const userPrefersDarkTheme = window.matchMedia('(prefers-color-scheme: dark').matches

  // this is a temporary solution for dev purposes
  const [isDarkThemeOn, _setIsDarkThemeOn] = useState(() => {
    const isDarkThemeOnLocalStorageValue = localStorage.getItem(IS_DARK_THEME_ON_LOCAL_STORAGE_KEY)

    return isDarkThemeOnLocalStorageValue
      ? isDarkThemeOnLocalStorageValue === 'true'
      : userPrefersDarkTheme
  })

  const setIsDarkThemeOn = () => {
    localStorage.setItem(IS_DARK_THEME_ON_LOCAL_STORAGE_KEY, (!isDarkThemeOn).toString())
    _setIsDarkThemeOn(!isDarkThemeOn)
  }

  useKonami(() => setIsDarkThemeOn(), {
    code: ['ArrowUp', 'ArrowDown', 'Enter'],
  })

  return <CuiThemeProvider theme={isDarkThemeOn ? 'cui-dark' : 'cui-light'}>{children}</CuiThemeProvider>
}
