import { Link, Outlet, useMatchRoute } from '@tanstack/react-location'
import { type FunctionComponent, Fragment } from 'react'
import { type APISchemas } from 'src/api/api'
import { stringifyCourseCode } from 'src/api/course'
import { CuiSpacer, CuiTabs, CuiText } from 'src/cui/components'

import { CourseData } from './CourseData'
import { CourseOverview } from './CourseOverview'
import { CourseReviews } from './CourseReviews'
import { CourseSchedule } from './CourseSchedule'

type Props = { course: APISchemas['MetaOffering'] }

type CourseDetailTab = 'overview' | 'schedule' | 'data' | 'reviews'

const CourseTabs = {
  overview: CourseOverview,
  schedule: CourseSchedule,
  data: CourseData,
  reviews: CourseReviews,
}

export const CourseDetail: FunctionComponent<Props> = ({ course }) => {
  const matchRoute = useMatchRoute()

  const { courseCode, title } = course

  const activeTab = getActiveTab()

  const tabs = getTabs()

  const ActiveTabComponent = CourseTabs[activeTab]

  return (
    <Fragment>
      <CuiText size='title1'>{stringifyCourseCode(courseCode)}</CuiText>

      <CuiSpacer size='s' />

      <CuiText size='title2'>{title}</CuiText>

      <CuiSpacer size='s' />

      <CuiTabs items={tabs} />
      <CuiSpacer size='none' as='hr' />

      <CuiSpacer size='s' />

      <Outlet />

      <ActiveTabComponent course={course} />
    </Fragment>
  )

  function getTabs() {
    return [
      {
        label: 'Overview',
        active: activeTab === 'overview',
        as: Link,
        asProps: {
          to: 'overview',
        },
      },
      {
        label: 'Schedule',
        active: activeTab === 'schedule',
        as: Link,
        asProps: {
          to: 'schedule',
        },
      },
      {
        label: 'Data',
        active: activeTab === 'data',
        as: Link,
        asProps: {
          to: 'data',
        },
      },
      {
        label: 'Reviews',
        active: activeTab === 'reviews',
        as: Link,
        asProps: {
          to: 'reviews',
        },
      },
    ]
  }

  function getActiveTab(): CourseDetailTab {
    if (matchRoute({ to: 'schedule' })) {
      return 'schedule'
    }

    if (matchRoute({ to: 'data' })) {
      return 'data'
    }

    if (matchRoute({ to: 'reviews' })) {
      return 'reviews'
    }

    return 'overview'
  }
}
