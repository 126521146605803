import { useMatch } from '@tanstack/react-location'
import { type FunctionComponent, useEffect } from 'react'

export const ScrollToTopListener: FunctionComponent = () => {
  const { pathname } = useMatch()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}
