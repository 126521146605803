import { Link } from '@tanstack/react-location'
import classnames from 'classnames'
import { type FunctionComponent, Fragment } from 'react'
import { type APISchemas } from 'src/api/api'
import {
  requirementAbbreviation,
  seasonAbbreviation,
  stringifyCourseCode,
  stringifyUnits,
} from 'src/api/course'
import {
  CuiBadge,
  CuiFlexGroup,
  CuiLoadingSkeleton,
  CuiPad,
  CuiSpacer,
  CuiText,
} from 'src/cui/components'
import { useIsMobile } from 'src/utils/mediaQuery'
import styled from 'styled-components'

type Props = { course: APISchemas['MetaOffering'] }

const StylelessLink = styled(Link)`
  display: block;
  text-decoration: none;

  :hover {
    background-color: ${({ theme }) => theme.cuiColors.lighterShade};
  }

  &.CourseCard--isActive {
    background-color: ${({ theme }) => theme.cuiColors.lighterShade};
  }
`

export const CourseCard: FunctionComponent<Props> = ({ course }) => {
  const { courseCode, title, units, seasons, requirements, description } = course

  const isMobile = useIsMobile()

  return (
    <StylelessLink
      to={`${course.id}`}
      getActiveProps={() => ({
        className: classnames({
          'CourseCard--isActive': true,
        }),
      })}
    >
      <CuiPad size='s'>
        <CuiText size='title2'>{stringifyCourseCode(courseCode)}</CuiText>

        <CuiSpacer size='xs' />

        <CuiText size='title3'>{title}</CuiText>

        <CuiSpacer size='xs' />

        {isMobile && (
          <Fragment>
            <CuiText size='paragraph1' clamp={2}>
              {description}
            </CuiText>

            <CuiSpacer size='xs' />
          </Fragment>
        )}

        <CuiText size='paragraph2' color='hushed'>
          {stringifyUnits(units)} units ·{' '}
          {seasons.map((season) => seasonAbbreviation[season]).join(', ')}
        </CuiText>

        {requirements.value.length > 0 && (
          <Fragment>
            <CuiSpacer size='xs' />

            <CuiFlexGroup gutterSizeHorizontal='xs'>
              {requirements.value.map((requirement) => (
                <CuiBadge key={requirement}>
                  {requirementAbbreviation[requirement].toUpperCase()}
                </CuiBadge>
              ))}
            </CuiFlexGroup>
          </Fragment>
        )}
      </CuiPad>
    </StylelessLink>
  )
}

export const CourseCardSkeleton: FunctionComponent = () => {
  return (
    <div style={{ cursor: 'wait' }}>
      <CuiPad size='s'>
        <CuiText size='title2'>
          <CuiLoadingSkeleton width='50%' />
        </CuiText>

        <CuiSpacer size='xs' />

        <CuiText size='title3'>
          <CuiLoadingSkeleton />
        </CuiText>

        <CuiSpacer size='xs' />

        <CuiText size='paragraph2' color='hushed'>
          <CuiLoadingSkeleton />
        </CuiText>
      </CuiPad>
    </div>
  )
}
