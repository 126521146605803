import React, { type FunctionComponent } from 'react'
import { type APISchemas } from 'src/api/api'
import { requirementAbbreviation } from 'src/api/course'
import { CuiFilter, CuiFlexGroup, CuiPad } from 'src/cui/components'

type Quarter = APISchemas['TermSeason']
const quarterOptions: Quarter[] = ['Autumn', 'Winter', 'Spring', 'Summer']

type Unit = '1 unit' | '2 units' | '3 units' | '4 units' | '5 units' | '>5 units'
const unitOptions: Unit[] = ['1 unit', '2 units', '3 units', '4 units', '5 units', '>5 units']

type Day = 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday'
const dayOptions: Day[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

type Time = 'Before 10 AM' | '10 AM - 12 PM' | '12 PM - 2 PM' | '2PM - 5 PM' | 'After 5 PM'
const timeOptions: Time[] = ['Before 10 AM', '10 AM - 12 PM', '12 PM - 2 PM', '2PM - 5 PM', 'After 5 PM']

type Ways = APISchemas['UndergradRequirement']
const waysOptions: Ways[] = [
  'WayAII',
  'WayAQR',
  'WayCE',
  'WayED',
  'WayER',
  'WayFR',
  'WaySI',
  'WaySMA',
  'Language',
  'Write1',
  'Write2',
  'WriteSLE',
  'College',
]

export type SearchType = {
  quarters?: Quarter[]
  units?: Unit[]
  days?: Day[]
  times?: Time[]
  ways?: Ways[]
}

export type HandleFilterParams =
  | { searchFilters: Quarter[]; filterName: 'quarters' }
  | { searchFilters: Unit[]; filterName: 'units' }
  | { searchFilters: Day[]; filterName: 'days' }
  | { searchFilters: Time[]; filterName: 'times' }
  | { searchFilters: Ways[]; filterName: 'ways' }

type Props = { selectedOptions: SearchType; handleFilter: (filteredOutput: HandleFilterParams) => void }

export const CourseFiltersGroup: FunctionComponent<Props> = ({ selectedOptions, handleFilter }) => {
  return (
    <CuiPad topSize='s'>
      <CuiFlexGroup gutterSize='s'>
        <CuiFilter
          label='Quarters'
          options={quarterOptions}
          selectedOptions={selectedOptions.quarters ?? []}
          setSelectedOptions={(searchFilters) => {
            handleFilter({ searchFilters, filterName: 'quarters' })
          }}
          getOptionKey={(option) => option}
          getOptionLabel={(option) => option}
        />

        <CuiFilter
          label='Units'
          options={unitOptions}
          selectedOptions={selectedOptions.units ?? []}
          setSelectedOptions={(searchFilters) => handleFilter({ searchFilters, filterName: 'units' })}
          getOptionKey={(option) => option}
          getOptionLabel={(option) => option}
        />
        <CuiFilter
          label='Days'
          options={dayOptions}
          selectedOptions={selectedOptions.days ?? []}
          setSelectedOptions={(searchFilters) => handleFilter({ searchFilters, filterName: 'days' })}
          getOptionKey={(option) => option}
          getOptionLabel={(option) => option}
        />

        <CuiFilter
          label='Time Offered'
          options={timeOptions}
          selectedOptions={selectedOptions.times ?? []}
          setSelectedOptions={(searchFilters) => {
            handleFilter({ searchFilters, filterName: 'times' })
          }}
          getOptionKey={(option) => option}
          getOptionLabel={(option) => option}
        />

        <CuiFilter
          label='WAYS'
          options={waysOptions}
          selectedOptions={selectedOptions.ways ?? []}
          setSelectedOptions={(searchFilters) => handleFilter({ searchFilters, filterName: 'ways' })}
          getOptionKey={(option) => option}
          getOptionLabel={(option) => requirementAbbreviation[option]}
        />
      </CuiFlexGroup>
    </CuiPad>
  )
}
