import * as RadixPopover from '@radix-ui/react-popover'
import { type FunctionComponent, Fragment, useState } from 'react'
import ReactModal from 'react-modal'
import { useIsMobile } from 'src/utils/mediaQuery'
import styled from 'styled-components'

import { CuiButton } from '../CuiButton'
import { CuiCheckbox } from '../CuiCheckbox'
import { CuiFilterButton } from '../CuiFilterButton'
import { CuiFlexGroup } from '../CuiFlexGroup'
import { CuiLink } from '../CuiLink'
import { CuiPad } from '../CuiPad'
import { CuiSpacer } from '../CuiSpacer'
import { CuiText } from '../CuiText'

const filterAnchorWidth = 200

interface Props<TOption> {
  /**
   * Options for the filter menu
   */
  options: TOption[]

  /*
   * get the label for each option
   */
  getOptionLabel: (item: TOption) => string

  /*
   * get the key for each option
   */
  getOptionKey: (item: TOption) => string

  /**
   * Selected options for the filter menu
   */
  selectedOptions: TOption[]

  /**
   * Callback for when save button is clicked
   */
  setSelectedOptions: (selectedOptions: TOption[]) => void

  /**
   * Label for the filter
   */
  label: string
}

export const CuiFilterRoot = styled(RadixPopover.Content)`
  border-radius: 8px;
  box-shadow: 0px 0px 12px 0px ${({ theme }) => theme.cuiColors.boxShadow};
  background-color: ${({ theme }) => theme.cuiColors.lightestShade};
  margin-top: 4px;

  display: flex;
  max-width: ${filterAnchorWidth * 2}px;
  min-width: ${filterAnchorWidth}px;
  flex-direction: column;
  z-index: ${({ theme }) => theme.cuiStackingLevels.dropdown};
`

export const CuiFilterTrigger = styled(RadixPopover.Trigger)`
  all: unset;
  display: inline-flex;
  max-width: ${filterAnchorWidth}px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background-color: ${({ theme }) => theme.cuiColors.overlayHover01};
    border-radius: 2px;
  }
`

// adds a background to the focused option
const CuiFilterOptionRoot = styled.div`
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.cuiColors.overlayHover01};
  }
`

const CuiFilterClose = styled(CuiButton)`
  position: absolute;
  top: calc(${({ theme }) => theme.cuiSpacingSizes.xs}px / 2);
  left: ${({ theme }) => theme.cuiSpacingSizes.s}px;
`

type CuiFilterOptionProps = {
  /**
   *
   * React key for the filter option
   */
  key: string

  /**
   *
   * click handler for the entire filter option
   */
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void

  /**
   *
   * `true` if the option is checked
   */
  checked: boolean

  /**
   *
   * onChange handler for checkbox option
   */
  onCheckboxChange: (checked: boolean) => void

  /**
   *
   * text option of label
   */
  label: string

  /**
   *
   * `true` if window is mobile
   */
  isMobile?: boolean
}

const CuiFilterOption: FunctionComponent<CuiFilterOptionProps> = ({
  onClick,
  checked,
  onCheckboxChange,
  label,
  isMobile = false,
}) => (
  <CuiFilterOptionRoot onClick={onClick}>
    <CuiPad verticalSize='xs' horizontalSize='s'>
      <CuiFlexGroup
        gutterSize='m'
        alignItems='center'
        justifyContent={isMobile ? 'spaceBetween' : undefined}
      >
        {isMobile && <CuiText>{label}</CuiText>}
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div onClick={(e) => e.stopPropagation()} onKeyDown={(e) => e.stopPropagation()}>
          <CuiCheckbox checked={checked} onChange={onCheckboxChange} />
        </div>
        {!isMobile && <CuiText>{label}</CuiText>}
      </CuiFlexGroup>
    </CuiPad>
  </CuiFilterOptionRoot>
)

// This is shown on desktop, using Radix Popover
const CuiFilterDesktop: FunctionComponent<Props<any>> = ({
  options,
  selectedOptions,
  setSelectedOptions,
  label,
  getOptionKey,
  getOptionLabel,
}) => {
  const [localSelected, setLocalSelected] = useState(selectedOptions)

  const isItemsSelected = selectedOptions.length > 0

  return (
    <RadixPopover.Root
      onOpenChange={(open: boolean) => {
        if (open) {
          setLocalSelected(selectedOptions)
        }
      }}
    >
      <CuiFilterTrigger>
        <CuiFilterButton iconSide='right' iconType='caret-down' selected={isItemsSelected}>
          {getButtonLabel()}
        </CuiFilterButton>
      </CuiFilterTrigger>
      <CuiFilterRoot align='start'>
        <CuiPad verticalSize='xs'>
          {options.map((option) => (
            <CuiFilterOption
              key={getOptionKey(option)}
              onClick={() => onCheckedChange(option)}
              checked={localSelected.some(
                (selectedOption) => getOptionKey(selectedOption) === getOptionKey(option)
              )}
              onCheckboxChange={() => onCheckedChange(option)}
              label={getOptionLabel(option)}
            />
          ))}

          <CuiPad horizontalSize='s'>
            <CuiFlexGroup gutterSize='s' justifyContent='spaceBetween'>
              <RadixPopover.Close asChild={true}>
                <CuiLink size='paragraph2' onClick={onClear}>
                  Clear
                </CuiLink>
              </RadixPopover.Close>
              <RadixPopover.Close asChild={true}>
                <CuiButton size='s' onClick={onSave}>
                  Save
                </CuiButton>
              </RadixPopover.Close>
            </CuiFlexGroup>
          </CuiPad>
        </CuiPad>
      </CuiFilterRoot>
    </RadixPopover.Root>
  )

  function onCheckedChange(option: string) {
    setLocalSelected((currentSelection) => {
      if (
        currentSelection.some((selectedOption) => getOptionKey(selectedOption) === getOptionKey(option))
      ) {
        return currentSelection.filter((selected) => selected !== option)
      }

      return [...currentSelection, option]
    })
  }

  function onClear() {
    setLocalSelected([])
    setSelectedOptions([])
  }

  function onSave() {
    setSelectedOptions(localSelected)
  }

  function getButtonLabel() {
    if (selectedOptions.length === 0) {
      return label
    }

    let buttonLabel = selectedOptions[0]
    if (selectedOptions.length > 1) {
      buttonLabel += ` +${selectedOptions.length - 1}`
    }

    return buttonLabel
  }
}

const CuiFilterMobileRoot = styled(ReactModal)`
  background-color: ${({ theme }) => theme.cuiColors.lightestShade};
  box-shadow: 0px 0px 12px 0px ${({ theme }) => theme.cuiColors.boxShadow};
`

const CuiFilterMobile: FunctionComponent<Props<any>> = ({
  options,
  selectedOptions,
  setSelectedOptions,
  label,
  getOptionKey,
  getOptionLabel,
}) => {
  const [localSelected, setLocalSelected] = useState(selectedOptions)
  const [isOpen, setIsOpen] = useState(false)

  const isItemsSelected = selectedOptions.length > 0

  return (
    <Fragment>
      <CuiFilterMobileRoot
        isOpen={isOpen}
        contentLabel={label}
        style={{
          content: {
            top: 'auto',
            bottom: 0,
            width: '100%',
            position: 'absolute',
            borderRadius: '16px 16px 0 0',

            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            outline: 'none',
          },
        }}
      >
        <CuiPad size='xs'>
          <CuiFilterClose size='s' onClick={onClose} iconType='close' />
          <CuiFlexGroup justifyContent='center'>
            <CuiText size='title2'>{label}</CuiText>
          </CuiFlexGroup>
          <CuiSpacer as='hr' size='xs' />
          {options.map((option) => (
            <CuiFilterOption
              key={getOptionKey(option)}
              onClick={() => onCheckedChange(option)}
              checked={localSelected.some(
                (selectedOption) => getOptionKey(selectedOption) === getOptionKey(option)
              )}
              onCheckboxChange={() => onCheckedChange(option)}
              label={getOptionLabel(option)}
              isMobile={true}
            />
          ))}
          <CuiSpacer as='hr' size='xs' />
          <CuiPad horizontalSize='s' bottomSize='xs'>
            <CuiFlexGroup justifyContent='spaceBetween'>
              <CuiLink
                size='paragraph2'
                onClick={() => {
                  setIsOpen(false)
                  onClear()
                }}
              >
                Clear
              </CuiLink>
              <CuiButton
                size='s'
                onClick={() => {
                  setIsOpen(false)
                  setSelectedOptions(localSelected)
                }}
              >
                Save
              </CuiButton>
            </CuiFlexGroup>
          </CuiPad>
        </CuiPad>
      </CuiFilterMobileRoot>
      <CuiFilterButton
        iconSide='right'
        iconType='caret-down'
        onClick={() => setIsOpen(true)}
        selected={isItemsSelected}
      >
        {getButtonLabel()}
      </CuiFilterButton>
    </Fragment>
  )

  function onCheckedChange(option: string) {
    setLocalSelected((currentSelection) => {
      if (currentSelection.includes(option)) {
        return currentSelection.filter((selected) => selected !== option)
      }

      return [...currentSelection, option]
    })
  }

  function onClear() {
    setLocalSelected([])
    setSelectedOptions([])
  }

  function onClose() {
    setIsOpen(false)
    setLocalSelected(selectedOptions)
  }

  function getButtonLabel() {
    if (selectedOptions.length === 0) {
      return label
    }

    let buttonLabel = selectedOptions[0]
    if (selectedOptions.length > 1) {
      buttonLabel += ` +${selectedOptions.length - 1}`
    }

    return buttonLabel
  }
}

export const CuiFilter = <TOption,>({
  options,
  selectedOptions,
  setSelectedOptions,
  label,
  getOptionLabel,
  getOptionKey,
}: Props<TOption>) => {
  const isMobile = useIsMobile()

  if (isMobile) {
    return (
      <CuiFilterMobile
        options={options}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        label={label}
        getOptionKey={getOptionKey}
        getOptionLabel={getOptionLabel}
      />
    )
  }

  return (
    <CuiFilterDesktop
      options={options}
      selectedOptions={selectedOptions}
      setSelectedOptions={setSelectedOptions}
      label={label}
      getOptionKey={getOptionKey}
      getOptionLabel={getOptionLabel}
    />
  )
}
