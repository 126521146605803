import { type FunctionComponent, Fragment } from 'react'
import { type APISchemas } from 'src/api/api'
import { friendlyAttendanceText } from 'src/api/course'
import { CuiSpacer, CuiText } from 'src/cui/components'

type Props = { course: APISchemas['MetaOffering'] }

export const CourseSchedule: FunctionComponent<Props> = ({ course }) => {
  const { attendance } = course

  return (
    <Fragment>
      <CuiText size='title2'>Current academic year</CuiText>

      <CuiSpacer size='s' />

      <div>
        <CuiText size='title3'>Attendance</CuiText>
        <CuiText size='paragraph1'>{friendlyAttendanceText[attendance.value]}</CuiText>
      </div>
    </Fragment>
  )
}
