import * as RadixScrollArea from '@radix-ui/react-scroll-area'
import { rgba } from 'polished'
import { type FunctionComponent } from 'react'
import styled from 'styled-components'

// Styles adapted from https://www.radix-ui.com/docs/primitives/components/scroll-area#scroll-area example (https://codesandbox.io/s/xx8zpl)

const CuiScrollAreaRoot = styled(RadixScrollArea.Root)`
  overflow: hidden;
`

const CuiScrollAreaViewport = styled(RadixScrollArea.Viewport)`
  width: 100%;
  height: 100%;
  border-radius: inherit;
`

const CuiScrollAreaScrollbar = styled(RadixScrollArea.Scrollbar)`
  display: flex;
  /* ensures no selection */
  user-select: none;
  /* disable browser handling of all panning and zooming gestures on touch devices */
  touch-action: none;
  padding: 2px;
  background: ${({ theme }) => rgba(theme.cuiColors.text, 0.1)};
  transition: background 160ms ease-out;

  :hover {
    background: ${({ theme }) => rgba(theme.cuiColors.text, 0.2)};
  }

  &[data-orientation='vertical'] {
    width: 10px;
  }

  &[data-orientation='horizontal'] {
    flex-direction: column;
    height: 10px;
  }
`

const CuiScrollAreaThumb = styled(RadixScrollArea.Thumb)`
  flex: 1;
  background: ${({ theme }) => rgba(theme.cuiColors.text, 0.5)};
  border-radius: 10px;
  position: relative;

  /* increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html */
  ::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    min-width: 44px;
    min-height: 44px;
  }
`

const CuiScrollAreaCorner = styled(RadixScrollArea.Corner)`
  background: ${({ theme }) => rgba(theme.cuiColors.text, 0.2)};
`

type Props = {}

export const CuiScrollArea: FunctionComponent<Props> = ({ children }) => {
  return (
    <CuiScrollAreaRoot>
      <CuiScrollAreaViewport>{children}</CuiScrollAreaViewport>
      <CuiScrollAreaScrollbar orientation='vertical'>
        <CuiScrollAreaThumb className='ScrollAreaThumb' />
      </CuiScrollAreaScrollbar>
      <CuiScrollAreaScrollbar orientation='horizontal'>
        <CuiScrollAreaThumb className='ScrollAreaThumb' />
      </CuiScrollAreaScrollbar>
      <CuiScrollAreaCorner className='ScrollAreaCorner' />
    </CuiScrollAreaRoot>
  )
}
