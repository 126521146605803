import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu'
import { type FunctionComponent, type ReactNode } from 'react'
import styled from 'styled-components'

import { CuiFlexGroup } from '../CuiFlexGroup'
import { CuiPad } from '../CuiPad'
import { CuiText } from '../CuiText'

interface Props<TOption> {
  /**
   * Options for the dropdown menu
   */
  options: TOption[]

  /*
   * get the click function for each option
   */
  getOptionSelect: (item: TOption) => void

  /*
   * get the label for each option
   */
  getOptionLabel: (item: TOption) => string

  /*
   * get the key for each option
   */
  getOptionKey: (item: TOption) => string

  /**
   * Content for the dropdown trigger
   */
  trigger: ReactNode
}

export const CuiDropdownRoot = styled(RadixDropdownMenu.Content)`
  border-radius: 8px;
  box-shadow: 0px 0px 12px 0px ${({ theme }) => theme.cuiColors.boxShadow};
  background-color: ${({ theme }) => theme.cuiColors.lightestShade};
  margin-top: 4px;

  display: flex;
  flex-direction: column;
`
export const CuiDropdownTrigger = styled(RadixDropdownMenu.Trigger)`
  all: unset;
  display: inline-flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background-color: ${({ theme }) => theme.cuiColors.overlayHover01};
    border-radius: 2px;
  }
`
const CuiDropdownOptionRoot = styled(RadixDropdownMenu.Item)`
  cursor: pointer;
  user-select: none;
  outline: none;

  &[data-highlighted] {
    background-color: ${({ theme }) => theme.cuiColors.overlayHover01};
  }
`

type CuiDropdownOptionProps = {
  /**
   *
   * React key for the filter option
   */
  key: string

  /**
   *
   * click handler for the entire filter option
   */
  onSelect: (event: Event) => void

  /**
   *
   * text option of label
   */
  label: string
}

const CuiDropdownOption: FunctionComponent<CuiDropdownOptionProps> = ({ onSelect, label }) => (
  <CuiDropdownOptionRoot onSelect={onSelect}>
    <CuiPad verticalSize='xs' horizontalSize='s'>
      <CuiFlexGroup gutterSize='m' alignItems='center' justifyContent='spaceBetween'>
        <CuiText>{label}</CuiText>
      </CuiFlexGroup>
    </CuiPad>
  </CuiDropdownOptionRoot>
)

export const CuiDropdown = <TOption,>({
  options,
  trigger,
  getOptionSelect,
  getOptionKey,
  getOptionLabel,
}: Props<TOption>) => {
  return (
    <RadixDropdownMenu.Root>
      <CuiDropdownTrigger>{trigger}</CuiDropdownTrigger>
      <RadixDropdownMenu.Portal>
        <CuiDropdownRoot align='start'>
          <CuiPad verticalSize='xs'>
            {options.map((option) => (
              <CuiDropdownOption
                key={getOptionKey(option)}
                onSelect={() => getOptionSelect(option)}
                label={getOptionLabel(option)}
              />
            ))}
          </CuiPad>
        </CuiDropdownRoot>
      </RadixDropdownMenu.Portal>
    </RadixDropdownMenu.Root>
  )
}
