import { type DefaultGenerics, type Route, Navigate } from '@tanstack/react-location'
import { CuiThemeProvider } from 'src/cui/CuiThemeProvider'

import { DashboardContainer } from './DashboardContainer'
import { Home } from './Home'
import { LandingPage } from './LandingPage'
import { Planner } from './Planner'

const staticRoutes: Route<DefaultGenerics>[] = [
  {
    path: '/landing',
    element: (
      // force light theme for static pages
      <CuiThemeProvider theme='cui-light'>
        <LandingPage />
      </CuiThemeProvider>
    ),
  },
  {
    path: '/about',
    element: 'About page!!',
  },
  {
    path: '/apply',
    element: 'Apply page!!',
  },
]

const authenticatedRoutes: Route<DefaultGenerics>[] = [
  {
    path: '/planner',
    element: (
      <DashboardContainer>
        <Planner.Planner />
      </DashboardContainer>
    ),
  },
  {
    path: '/',
    element: (
      <DashboardContainer>
        <Home.Home />
      </DashboardContainer>
    ),
    children: [
      {
        path: ':courseId',
        element: <Home.CourseDetail />,
        // Tab routing is handled within CourseDetail
        children: [
          {
            path: '/overview',
          },
          {
            path: '/schedule',
          },
          {
            path: '/data',
          },
          {
            path: '/reviews',
          },
        ],
      },
    ],
  },
]

const isAuthenticated = true

export const routes = [...staticRoutes, ...wrapAuthenticatedRoutes(authenticatedRoutes)]

function wrapAuthenticatedRoutes(routes: Route<DefaultGenerics>[]): Route<DefaultGenerics>[] {
  return routes.map(wrapAuthenticatedRoute)
}

function wrapAuthenticatedRoute(route: Route<DefaultGenerics>): Route<DefaultGenerics> {
  return {
    ...route,
    loader: isAuthenticated ? route.loader : undefined,
    element: isAuthenticated ? route.element : <Navigate to='/landing' />,
    children: route.children ? wrapAuthenticatedRoutes(route.children) : undefined,
  }
}
