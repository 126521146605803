import { useNavigate } from '@tanstack/react-location'
import { type FunctionComponent, useState } from 'react'
import { stringifyCourseCode, useSearch } from 'src/api/course'
import { CuiPad, CuiSearch, CuiSpacer } from 'src/cui/components'
import styled from 'styled-components'
import { useDebouncedCallback as useDebounce } from 'use-debounce'

const PagePadding = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`
const debounceInterval = 300

export const DashboardContainer: FunctionComponent = ({ children }) => {
  const [searchValue, setSearchValue] = useState('')

  const { data } = useSearch({ year: 2023 }, { query: searchValue }, { enabled: !!searchValue })

  const onChange = useDebounce((value: string) => {
    setSearchValue(value)
  }, debounceInterval)

  const navigate = useNavigate()

  return (
    <PagePadding>
      <CuiPad horizontalSize='m' verticalSize='s'>
        <CuiSearch
          items={data?.entries ?? []}
          onChange={onChange}
          onResultClick={(result) => navigate({ to: result.id })}
          getItemHref={(item) => item.id}
          onSearch={onChange}
          value={searchValue}
          getItemTitle={(item) => `${stringifyCourseCode(item.courseCode)} ${item.title}`}
          getItemIcon={() => 'book'}
          emptyResultsMessage='No results found'
          placeholder='Search anything...'
        />
      </CuiPad>
      <CuiSpacer as='hr' size='none' />

      {children}
    </PagePadding>
  )
}
