import { omit } from 'lodash'
import { type FunctionComponent } from 'react'
import styled from 'styled-components'

import { type Props as CuiButtonProps, CuiButton, CuiButtonRoot } from '../CuiButton'
import { CuiFlexGroup } from '../CuiFlexGroup'

type CuiTabProps = {
  label: string
  active: boolean
} & Omit<CuiButtonProps, 'size' | 'color' | 'children'>

type Props = {
  /**
   *
   * The tab items
   */
  items: CuiTabProps[]
}

type CuiTabItemRootProps = {
  $active: boolean
  $disabled: boolean
}

const CuiTabsRoot = styled.div`
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`

const CuiTabItemRoot = styled.div<CuiTabItemRootProps>`
  > ${CuiButtonRoot} {
    border: none !important;
    border-radius: 0px;

    color: ${({ theme, $active }) =>
      $active ? theme.cuiColors.text : theme.cuiColors.lightestText} !important;

    background-color: transparent !important;

    :after {
      background-color: transparent !important;
    }
  }

  border-bottom: 4px solid ${({ theme, $active }) => ($active ? theme.cuiColors.accent : 'transparent')};

  ${({ theme, $active, $disabled }) =>
    !$active && !$disabled
      ? `:hover {
    border-bottom-color: ${theme.cuiColors.lightShade}
    }`
      : ''}
`

/**
 *
 * `<CuiTabs>`
 */
export const CuiTabs: FunctionComponent<Props> = ({ items }) => {
  return (
    <CuiTabsRoot>
      <CuiFlexGroup gutterSize='s'>
        {items.map((item, idx) => (
          <CuiTabItemRoot key={idx} $active={item.active} $disabled={item.disabled ?? false}>
            <CuiButton {...(omit(item, 'label', 'active') as any)}>{item.label}</CuiButton>
          </CuiTabItemRoot>
        ))}
      </CuiFlexGroup>
    </CuiTabsRoot>
  )
}
