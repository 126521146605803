import { type FunctionComponent } from 'react'
import { CuiFlexGroup, CuiText } from 'src/cui/components'
import { palette } from 'src/cui/themes/palette'
import styled from 'styled-components'

const FactRoot = styled.div`
  color: ${palette.white};
  max-width: 270px;
  min-width: 270px;
  padding: 16px 0;
  text-align: center;
`

type FactProps = { stat: string; description: string }

const Fact: FunctionComponent<FactProps> = ({ stat, description }) => {
  return (
    <FactRoot>
      <CuiText as='div' size='titleDisplay' color='inherit'>
        {stat}
      </CuiText>

      <CuiText size='paragraph1' color='inherit'>
        {description}
      </CuiText>
    </FactRoot>
  )
}

const FactsRoot = styled.div`
  background-color: ${palette.neutral90};
  padding: 128px 144px;
`

export const Facts: FunctionComponent = () => {
  return (
    <FactsRoot>
      <CuiFlexGroup direction='row' justifyContent='spaceEvenly' wrap={true}>
        <Fact stat='2016' description='V1 launched as a research project by the CartaLab.' />
        <Fact stat='5' description='research papers by the CartaLab enabled by this platform.' />
        <Fact stat='30+' description='undergraduate engineers and designers over the past 4 years.' />
        <Fact stat='95%' description='of undergraduate students use Carta regularly.' />
      </CuiFlexGroup>
    </FactsRoot>
  )
}
