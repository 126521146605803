import { type FunctionComponent, useState } from 'react'
import { CuiFlexGroup, CuiInput, CuiPad, CuiText } from 'src/cui/components'
import styled from 'styled-components'

const PlannerPageWrapper = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.cuiColors.lighterShade};
`

const PlannerHeaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.cuiColors.background};
`

type PlannerHeaderProps = {
  /**
   *
   * header search value
   */
  searchValue: string

  onSearchValueChange: (value: string) => void
}

const PlannerHeader: FunctionComponent<PlannerHeaderProps> = ({ searchValue, onSearchValueChange }) => {
  return (
    <PlannerHeaderWrapper>
      <CuiPad leftSize='m' verticalSize='l'>
        <CuiFlexGroup alignItems='center'>
          <CuiPad rightSize='xl'>
            <CuiText size='title2'>Your 4-Year Plan</CuiText>
          </CuiPad>
          <CuiInput
            placeholder='Add courses'
            iconType='search'
            iconSide='left'
            onChange={onSearchValueChange}
            value={searchValue}
          />
        </CuiFlexGroup>
      </CuiPad>
    </PlannerHeaderWrapper>
  )
}

const PlannerImpl = () => {
  const [searchValue, setSearchValue] = useState('')

  return (
    <PlannerPageWrapper>
      <PlannerHeader searchValue={searchValue} onSearchValueChange={setSearchValue} />
    </PlannerPageWrapper>
  )
}

export const Planner = {
  Planner: PlannerImpl,
}
