import { type MakeGenerics, Outlet, useMatches, useNavigate, useSearch } from '@tanstack/react-location'
import { type FunctionComponent, Fragment } from 'react'
import { useCourse, useInfiniteCourses } from 'src/api/course'
import {
  CuiFlexGroup,
  CuiInfiniteLoader,
  CuiLoadingSpinner,
  CuiPad,
  CuiScrollArea,
  CuiSpacer,
  CuiText,
} from 'src/cui/components'
import { useIsMobile } from 'src/utils/mediaQuery'
import { useMatchParams } from 'src/utils/useMatchParams'
import styled from 'styled-components'

import { CourseCard, CourseCardSkeleton } from './CourseCard'
import { CourseDetail } from './CourseDetail'
import type { SearchType } from './CourseFiltersGroup'
import { type HandleFilterParams, CourseFiltersGroup } from './CourseFiltersGroup'

const CoursesRightBorder = styled.div`
  border-right: 1px solid ${({ theme }) => theme.cuiColors.lightShade};
`
const GridWrapper = styled.div`
  overflow: hidden;
  display: grid;
  grid-template-columns: minmax(300px, 1fr) 3fr;
`
const LoadingCourses: FunctionComponent<{ hasTopBorder?: boolean }> = ({ hasTopBorder = false }) => {
  return (
    <Fragment>
      {hasTopBorder && <CuiSpacer as='hr' size='none' />}

      <CourseCardSkeleton />

      <CuiSpacer as='hr' size='none' />

      <CourseCardSkeleton />

      <CuiSpacer as='hr' size='none' />

      <CourseCardSkeleton />
    </Fragment>
  )
}

const CourseList: FunctionComponent<{ infiniteCourses: ReturnType<typeof useInfiniteCourses> }> = ({
  infiniteCourses,
}) => {
  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = infiniteCourses

  return (
    <Fragment>
      {data?.courses.map((course, idx) => (
        <Fragment key={course.id}>
          {idx > 0 && <CuiSpacer as='hr' size='none' />}

          <CourseCard course={course} />
        </Fragment>
      ))}

      <CuiInfiniteLoader
        hasNextPage={hasNextPage}
        isLoading={isLoading}
        isFetchingNextPage={isFetchingNextPage}
        loader={<LoadingCourses hasTopBorder={isFetchingNextPage} />}
        fetchNextPage={fetchNextPage}
      />
    </Fragment>
  )
}

export const HomeImpl: FunctionComponent = () => {
  const infiniteCourses = useInfiniteCourses({
    year: 2023,
  })

  const isMobile = useIsMobile()

  const matches = useMatches()
  const matchesCourseDetail = matches.some((match) => match.route.path === ':courseId')

  type SearchRouteParams = MakeGenerics<{
    Search: SearchType
  }>

  const selectedOptions = useSearch<SearchRouteParams>()

  const navigate = useNavigate<SearchRouteParams>()

  const filters = (
    <Fragment>
      <CuiPad horizontalSize='m' topSize='l' bottomSize='m'>
        <CuiText size='title2'>Search Results for “...”</CuiText>
        <CourseFiltersGroup selectedOptions={selectedOptions} handleFilter={handleFilter} />
      </CuiPad>
      <CuiSpacer as='hr' size='none' />
    </Fragment>
  )

  if (isMobile) {
    if (matchesCourseDetail) {
      return (
        <CuiPad size='m'>
          <Outlet />
        </CuiPad>
      )
    }

    return (
      <Fragment>
        {filters}

        <CourseList infiniteCourses={infiniteCourses} />
      </Fragment>
    )
  }

  return (
    <Fragment>
      {filters}

      <GridWrapper>
        <CuiScrollArea>
          <CoursesRightBorder>
            <CuiPad size='m'>
              <CourseList infiniteCourses={infiniteCourses} />
            </CuiPad>
          </CoursesRightBorder>
        </CuiScrollArea>

        <CuiScrollArea>
          <CuiPad verticalSize='m' horizontalSize='l'>
            <Outlet />
          </CuiPad>
        </CuiScrollArea>
      </GridWrapper>
    </Fragment>
  )

  function handleFilter(filterOutput: HandleFilterParams) {
    const urlFilters = filterOutput.searchFilters.length !== 0 ? filterOutput.searchFilters : undefined

    navigate({
      search: (prevSearchParams) => ({
        ...prevSearchParams,
        [filterOutput.filterName]: urlFilters,
      }),
    })
  }
}

const CourseDetailImpl: FunctionComponent = () => {
  const { courseId } = useMatchParams<{ courseId: string }>()

  const { data: course, isLoading, error } = useCourse({ id: courseId, year: 2023 })

  if (isLoading) {
    // TODO vertically center
    return (
      <CuiFlexGroup justifyContent='center' alignItems='center'>
        <CuiLoadingSpinner />
      </CuiFlexGroup>
    )
  }

  if (error || !course) {
    // TODO replace with error component
    return <Fragment>ERROR!</Fragment>
  }

  return <CourseDetail course={course} />
}

export const Home = {
  Home: HomeImpl,
  CourseDetail: CourseDetailImpl,
}
